import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import { ContactForm, CopyWithImages, Layout, SEO } from '../components';
import { CallToAction } from '../components/call-to-action';

function JoinUsPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityJoinUsPage.heroImage.asset.fluid}
      heroAltText={data.sanityJoinUsPage.heroImage.altText}
    >
      <SEO title="Join Us" />
      <JoinUs data={data.sanityJoinUsPage.joinUs} />
      <Banner />
      <ContactForm />
    </Layout>
  );
}

JoinUsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function JoinUs({ data }) {
  return (
    <CopyWithImages images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      <div className="flex flex-wrap items-center space-x-4">
        {data.callToAction ? (
          <CallToAction callToAction={data.callToAction} />
        ) : null}
      </div>
    </CopyWithImages>
  );
}

JoinUs.propTypes = {
  data: PropTypes.object.isRequired,
};

function Banner() {
  return (
    <article className="w-full px-4 py-16 mx-auto bg-gray-light max-w-7xl sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold text-center uppercase text-green">
        Koalas In Care Inc. thanks you for your kind support!
      </h2>
    </article>
  );
}

export const query = graphql`
  {
    sanityJoinUsPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      joinUs {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default JoinUsPage;
